import * as t from 'io-ts';

export const HistoricPositionCodec = t.type({
    latitude: t.number,
    longitude: t.number,
    occurred_at: t.string,
});

export const HistoricPositionsResponseCodec = t.type({
    asset_id: t.string,
    historic_positions: t.array(HistoricPositionCodec),
});
