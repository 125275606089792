import { ApiAsset, ApiAssetList, Asset, AssetList } from './assetAdministration.types';

export const mapAsset = (apiAsset: ApiAsset): Asset => {
    const { id, account_id, name, type, license_plate } = apiAsset;
    return {
        id,
        accountId: account_id,
        name,
        type,
        licensePlate: license_plate,
    };
};

export const mapAssetList = (apiAssetList: ApiAssetList): AssetList => {
    return {
        items: apiAssetList.items.map(mapAsset),
    };
};
