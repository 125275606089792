import { makeMapper } from '../makeMapper';
import { HistoricPositionCodec } from './asset-history.codecs';
import { ApiHistoricPosition, HistoricPosition } from './asset-history.types';

export const historicPositionMapper = makeMapper<HistoricPosition, ApiHistoricPosition>({
    codec: HistoricPositionCodec,
    toDomain: ({latitude, longitude, occurred_at: occurredAt}) => ({
        latitude, longitude, occurredAt
    })
});
