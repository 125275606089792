import { config } from '../../config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from '../../configuration/tokenHandling/prepareHeaders';
import { ApiHistoricPositionsResponse, HistoricPosition } from './asset-history.types';
import { historicPositionMapper } from './asset-history.mapper';

const baseUrl = `${config.backend.ASSET_HISTORY_SERVICE}`;

export type HistoricPositionQueryArg = {
    assetId: string;
    from: string;
    to: string;
};

export const assetHistoryApi = createApi({
    reducerPath: 'historicPositions',
    tagTypes: ['HistoricPositions'],
    baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders }),
    endpoints: builder => ({
        getHistoricPositionsForAsset: builder.query<HistoricPosition[], HistoricPositionQueryArg>({
            query: ({ assetId, from, to }) =>
                `/historic-positions/assets/${assetId}?from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`,
            transformResponse: (response: ApiHistoricPositionsResponse) => {
                return response.historic_positions.map(historicPositionMapper.toDomain);
            },
        }),
    }),
});

export const { useGetHistoricPositionsForAssetQuery } = assetHistoryApi;
