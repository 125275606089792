import { createContext, PropsWithChildren, useContext } from 'react';
import { DeliveryStatusLinks } from '../services/delivery-status-links/delivery-status-links.types';
import { useGetDeliveryStatusLinksQuery } from '../services/delivery-status-links/delivery-status-links.api';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';

export const DeliveryStatusLinkContext = createContext<DeliveryStatusLinks | undefined>(undefined);

const DeliveryStatusLinkContextProvider = ({ tourId, children }: PropsWithChildren<{ tourId: string }>) => {
    const { data, isLoading } = useGetDeliveryStatusLinksQuery(tourId);

    if (isLoading) {
        return <Spinner />;
    }

    return <DeliveryStatusLinkContext.Provider value={data}>{children}</DeliveryStatusLinkContext.Provider>;
};

export const useDeliveryStatusLinks = () => useContext(DeliveryStatusLinkContext);

export default DeliveryStatusLinkContextProvider;
