import * as t from 'io-ts';

export const ApiMeansOfTransportCodecProps = {
    asset_id: t.string,
};
export const ApiMeansOfTransportCodec = t.type(ApiMeansOfTransportCodecProps);

export const ApiTimeWindowCodec = t.type({
    from: t.string,
    to: t.string,
});

export const ApiCoordinatesCodec = t.type({
    latitude: t.number,
    longitude: t.number,
});

export const ApiAddressCodec = t.type({
    street: t.string,
    postal_code: t.string,
    city: t.string,
    country: t.string,
});

export const ApiLocationCodec = t.intersection([
    t.type({
        coordinates: ApiCoordinatesCodec,
    }),
    t.partial({
        address: ApiAddressCodec,
    }),
]);

export const ApiMetadataCodec = t.record(t.string, t.string);

export const ApiContactCodec = t.intersection([
    t.type({}),
    t.partial({
        name: t.string,
        email: t.string,
        has_agreed_to_receive_emails: t.boolean,
        phone: t.string,
        language: t.string,
    }),
]);

export const ApiShipmentCodec = t.intersection([
    t.type({
        id: t.string,
        type: t.string,
    }),
    t.partial({
        transport_order_id: t.string,
        metadata: ApiMetadataCodec,
        contact: ApiContactCodec,
    }),
]);

export const ApiSegmentCodec = t.intersection([t.type({ id: t.string, type: t.string }), t.partial({})]);

export const ApiTourStopCodec = t.intersection([
    t.type({
        id: t.string,
        planned_arrival: ApiTimeWindowCodec,
        location: ApiLocationCodec,
        shipments: t.array(ApiShipmentCodec),
    }),
    t.partial({
        metadata: ApiMetadataCodec,
    }),
]);

export const ApiTourCodec = t.intersection([
    t.type({
        id: t.string,
        account_id: t.string,
        stops: t.array(ApiTourStopCodec),
    }),
    t.partial({
        means_of_transport: ApiMeansOfTransportCodec,
        metadata: ApiMetadataCodec,
        status: t.string,
    }),
]);

export const ApiTourListCodec = t.type({
    items: t.array(ApiTourCodec),
});

export const ApiTourStopExecutionStateCodec = t.intersection([
    t.type({
        tour_stop_id: t.string,
        status: t.string,
    }),
    t.partial({
        eta: t.string,
    }),
]);

export const ApiLatestPositionCodec = t.type({
    latitude: t.number,
    longitude: t.number,
    occurred_at: t.string,
});

export const ApiTourExecutionStateCodec = t.intersection([
    t.type({
        tour_id: t.string,
        stop_execution_states: t.array(ApiTourStopExecutionStateCodec),
    }),
    t.partial({
        latest_position: ApiLatestPositionCodec,
    }),
]);

export const ApiTourExecutionStateListCodec = t.type({
    items: t.array(ApiTourExecutionStateCodec),
});
