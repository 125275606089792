import parseISO from 'date-fns/parseISO';
import { FormattedDate, FormattedTime } from 'react-intl';

type TimeWindowProps = {
    startAt?: string;
    finishAt?: string;
};

const TimeWindow = ({ startAt, finishAt }: TimeWindowProps) => {
    const startAtDate = startAt && parseISO(startAt);
    const finishAtDate = finishAt && parseISO(finishAt);
    return (
        <div>
            <>
                <FormattedDate value={startAtDate} day='2-digit' month='2-digit' year='numeric' />
                {', '}
                <FormattedTime value={startAtDate} />
            </>
            {finishAtDate ? (
                <>
                    {' - '}
                    <FormattedDate value={finishAtDate} day='2-digit' month='2-digit' year='numeric' />
                    {', '}
                    <FormattedTime value={finishAtDate} />
                </>
            ) : null}
        </div>
    );
};

export default TimeWindow;
