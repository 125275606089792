import { Address } from '../services/tours/tours.types';

type AddressProps = {
    address?: Address;
};

const AddressView = ({ address }: AddressProps) => {
    if (!address) {
        return null;
    }
    return <span>{`${address.street}, ${address.postalCode} ${address.city}, ${address.country}`}</span>;
};

export default AddressView;
