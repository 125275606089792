import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from '../../configuration/tokenHandling/prepareHeaders';
import { mapAssetList } from './assetAdministration.mapper';
import { ApiAssetList, AssetList } from './assetAdministration.types';
import { config } from '../../config';

const handleResponse = async (response: Response) => {
    if (response.ok) {
        try {
            return response.json();
        } catch (error) {
            throw new Error(`${response.status} Invalid payload: ${error}`);
        }
    }
    if (response.status === 401) {
        throw new Error(`${response.status} Unauthorized: ${response.statusText}`);
    }
    throw new Error(`${response.status} Backend error: ${response.statusText}`);
};

export const assetAdministrationApi = createApi({
    reducerPath: 'assetsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config.backend.ASSET_ADMINISTRATION_SERVICE,
        prepareHeaders,
    }),
    endpoints: builder => ({
        fetchAssets: builder.query<AssetList, void>({
            query: () => ({
                url: '/assets',
                responseHandler: async (response: Response) => handleResponse(response),
            }),
            transformResponse: (response: ApiAssetList) => mapAssetList(response),
        }),
    }),
    refetchOnFocus: true,
});

export const { useFetchAssetsQuery } = assetAdministrationApi;
