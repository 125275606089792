import * as t from 'io-ts';

export const ApiTimeWindowCodec = t.type({
    start_at: t.string,
    finish_at: t.string,
});

export const ApiCoordinatesCodec = t.type({
    latitude: t.number,
    longitude: t.number,
});

export const ApiAddressCodec = t.type({
    street: t.string,
    postal_code: t.string,
    city: t.string,
    country: t.string,
});

export const ApiContactCodec = t.partial({
    name: t.string,
    email: t.string,
    phone: t.string,
    has_agreed_to_receive_emails: t.boolean,
});

export const ApiPartyCodec = t.intersection([
    t.type({
        name: t.string,
    }),
    t.partial({
        account_id: t.string,
        duns: t.string,
        address: ApiAddressCodec,
        contact: ApiContactCodec,
        plant_code: t.string,
    }),
]);

export const ApiLocationCodec = t.intersection([
    t.type({}),
    t.partial({
        address: ApiAddressCodec,
        coordinates: ApiCoordinatesCodec,
    }),
]);

export const ApiLoadingCodec = t.intersection([
    t.type({
        time_window: ApiTimeWindowCodec,
        shipper: ApiPartyCodec,
        location: ApiLocationCodec,
    }),
    t.partial({
        access_code: t.string,
        note: t.string,
        place_of_loading: t.string,
    }),
]);

export const ApiUnloadingCodec = t.intersection([
    t.type({
        recipient: ApiPartyCodec,
        location: ApiLocationCodec,
    }),
    t.partial({
        time_window: ApiTimeWindowCodec,
        access_code: t.string,
        note: t.string,
        place_of_discharge: t.string,
    }),
]);

export const ApiTransportOrderExecutionStateCodec = t.intersection([
    t.type({
        transport_order_id: t.string,
        root_id: t.string,
    }),
    t.partial({}),
]);

export const ApiEmbeddingCodec = t.intersection([
    t.type({}),
    t.partial({
        transport_order_execution_state: ApiTransportOrderExecutionStateCodec,
    }),
]);

export const ApiTransportOrderCodec = t.intersection([
    t.type({
        id: t.string,
        root_id: t.string,
        loading: ApiLoadingCodec,
        unloading: ApiUnloadingCodec,
    }),
    t.partial({
        external_id: t.string,
        _embedded: ApiEmbeddingCodec,
    }),
]);

export const ApiTransportOrderListCodec = t.type({
    items: t.array(ApiTransportOrderCodec),
});
