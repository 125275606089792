export const HelpText = () => (
    <>
        <p>
            Das Feature "Lieferstatus & ETA" ermöglicht es Ihnen, den aktuellen Lieferstatus eines Auftrags über einen
            Tracking-Link mit ihren Kunden oder anderen Prozessbeteiligten zu teilen.
        </p>

        <p>
            Um den Tracking-Link zu generieren, müssen Sie die geplanten Tourstopps mit den jeweiligen Sendungen
            erfassen und dem zugehörigen Fahrzeug zuordnen.
        </p>

        <p>
            Pro Stopp und Sendung wird ein Tracking-Link erzeugt, den Sie ganz einfach kopieren und an ihren Kunden
            versenden können.
        </p>

        <p>
            Ihr Kunde kann dann über diesen Link die geplante Ankunftszeit der Sendung einsehen. Sobald die Tour
            gestartet ist, wird basierend auf der aktuellen Geo-Position Ihres Fahrzeugs die voraussichtliche
            Ankunftszeit dynamisch berechnet und angezeigt.
        </p>
    </>
);
