import SimpleTooltip from '@rio-cloud/rio-uikit/lib/es/SimpleTooltip';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';

import TimeWindow from './TimeWindow';
import AddressView from './AddressView';
import { TourStop, TourStopExecutionState } from '../services/tours/tours.types';
import DeliveryDisplay from './DeliveryDisplay';

type StatusBadgeProps = {
    stopExecutionState?: TourStopExecutionState;
};

type StopDisplayProps = {
    stop: TourStop;
    stopExecutionState?: TourStopExecutionState;
    isFirstStop: boolean;
    isLastStop: boolean;
};

const StatusBadge = ({ stopExecutionState }: StatusBadgeProps) => {
    const status = stopExecutionState?.status;
    if (!status) {
        return null;
    }
    return <span className='badge badge-default'>{status}</span>;
};

const StopDisplay = ({ stop, stopExecutionState, isFirstStop, isLastStop }: StopDisplayProps) => {
    const fromAddress = stop.location.address;
    const fromStartAt = stop.plannedArrival?.from;
    const fromFinishAt = stop.plannedArrival?.to;

    const stopIcon = isFirstStop ? 'rioglyph-start' : isLastStop ? 'rioglyph-finish' : 'rioglyph-stopover';

    return (
        <div className={`timeline-element ${isLastStop ? '' : 'margin-bottom-0'}`}>
            <div className={`padding-left-2 margin-right-15 ${isLastStop ? '' : 'timeline-date'}`}>
                <span className={`text-color-dark text-size-16 rioglyph ${stopIcon}`} />
            </div>
            <div className={`timeline-content flex-1-1 display-flex gap-5 ${isLastStop ? '' : 'margin-bottom-25'}`}>
                <div className='flex-1-1'>
                    <div className='margin-bottom-5 line-height-125rel'>
                        <AddressView address={fromAddress} />
                    </div>
                    <div className='margin-bottom-5 line-height-125rel'>
                        <StatusBadge stopExecutionState={stopExecutionState} />
                    </div>
                    <div className='display-flex gap-5 text-italic text-color-darker'>
                        <span className={'rioglyph rioglyph-time text-color-dark padding-top-3'} />
                        <TimeWindow startAt={fromStartAt} finishAt={fromFinishAt} />
                    </div>
                    <div>
                        {!isFirstStop && stop.shipments.length > 0 && (
                            <ExpanderPanel
                                title='Lieferungen'
                                bsStyle='default'
                                className='margin-top-15'
                                bodyClassName='padding-0'
                            >
                                {stop.shipments.map((shipment, index) => (
                                    <DeliveryDisplay key={shipment.id} shipment={shipment} index={index} />
                                ))}
                            </ExpanderPanel>
                        )}
                    </div>
                </div>

                {isFirstStop && (
                    <div className='display-flex gap-5'>
                        <SimpleTooltip placement='bottom' content='Tour löschen'>
                            <button type='button' className='btn btn-default btn-icon-only btn-sm' disabled>
                                <span className='rioglyph rioglyph-trash' />
                            </button>
                        </SimpleTooltip>
                    </div>
                )}
            </div>
        </div>
    );
};

export default StopDisplay;
