import SimpleDialog from '@rio-cloud/rio-uikit/lib/es/SimpleDialog';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { useState } from 'react';
import { HelpText } from '../../components/HelpText';

const ServiceInfo = () => {
    const [showDialog, setShowDialog] = useState(false);

    const onShowDialog = () => setShowDialog(true);
    const onCloseDialog = () => setShowDialog(false);

    return (
        <>
            {/* Workaround because ActionBar UI Kit Component uses common.js, which is not compatible with our setup right now*/}
            <div className='ActionBarItem'>
                <div className='ActionBarItemIcon' aria-describedby='infoMenu'>
                    <span className='icon rioglyph rioglyph-info-sign' onClick={onShowDialog} />
                </div>
            </div>
            <HelpDialog show={showDialog} onClose={onCloseDialog} />
        </>
    );
};

const ServiceInfoWithErrorBoundary = () => {
    return (
        <ErrorBoundary>
            <ServiceInfo />
        </ErrorBoundary>
    );
};

const HelpDialog = ({ show, onClose }: { show: boolean; onClose: () => void }) => (
    <SimpleDialog show={show} title='Hilfe zu Lieferstatus & ETA' content={<HelpText />} onClose={onClose} />
);

export default ServiceInfoWithErrorBoundary;
