import { lazy } from 'react';
import { IntlProvider } from 'react-intl';
import { Route, Routes } from 'react-router-dom';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';

import { DEFAULT_LOCALE } from '../configuration/lang/lang';
import { isUserSessionExpired } from '../configuration/login/loginSlice';
import { useAppDispatch, useAppSelector } from '../configuration/setup/hooks';
import { getDisplayMessages, getLocale } from '../configuration/lang/langSlice';
import { DEFAULT_ROUTE, WIDGET_ROUTE } from '../routes/routes';
import DefaultRedirect from '../routes/DefaultRedirect';
import { getSessionExpiredAcknowledged, hideSessionExpiredDialog } from './appSlice';
import AppHeader from '../features/header/AppHeader';
import SuspendedWithSpinner from '../components/SuspendedWithSpinner';
import TourSidebar from '../features/tourSidebar/TourSidebar';

// Lazy load pages for better performance and automatically split the bundle accordingly
const Tours = lazy(() => import('../pages/Tours'));
const TourWidget = lazy(() => import('../pages/TourWidget'));

const App = () => {
    const dispatch = useAppDispatch();

    const userLocale = useAppSelector(getLocale);
    const displayMessages = useAppSelector(getDisplayMessages);
    const isSessionExpired = useAppSelector(isUserSessionExpired);
    const sessionExpiredAcknowledged = useAppSelector(getSessionExpiredAcknowledged);

    if (!(displayMessages && userLocale)) {
        return null;
    }

    const handleSessionExpiredDialogClose = () => dispatch(hideSessionExpiredDialog);
    const showSessionExpired = isSessionExpired && !sessionExpiredAcknowledged;

    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={displayMessages}>
            <Routes>
                <Route
                    path={`${DEFAULT_ROUTE}/*`}
                    element={
                        <SuspendedWithSpinner>
                            <ApplicationLayout className={'Tours'}>
                                <ApplicationLayout.Header>
                                    <AppHeader />
                                </ApplicationLayout.Header>
                                <ApplicationLayout.Sidebar className='right'>
                                    <TourSidebar />
                                </ApplicationLayout.Sidebar>
                                <ApplicationLayout.Body>
                                    <NotificationsContainer />
                                    <SessionExpiredDialog
                                        locale={userLocale}
                                        onClose={handleSessionExpiredDialogClose}
                                        show={showSessionExpired}
                                    />
                                    <Tours />
                                </ApplicationLayout.Body>
                            </ApplicationLayout>
                        </SuspendedWithSpinner>
                    }
                />
                <Route path={WIDGET_ROUTE}>
                    <Route
                        index
                        element={
                            <SuspendedWithSpinner>
                                <ApplicationLayout>
                                    <TourWidget />
                                </ApplicationLayout>
                            </SuspendedWithSpinner>
                        }
                    />
                    <Route
                        path=':assetIdFromParams'
                        element={
                            <SuspendedWithSpinner>
                                <ApplicationLayout>
                                    <TourWidget />
                                </ApplicationLayout>
                            </SuspendedWithSpinner>
                        }
                    />
                </Route>
                <Route path='*' element={<DefaultRedirect />} />
            </Routes>
        </IntlProvider>
    );
};

export default App;
