import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../config';
import { prepareHeaders } from '../../configuration/tokenHandling/prepareHeaders';
import { transportOrderListMapper, transportOrderMapper } from './transportOrders.mapper';
import { TransportOrder, TransportOrderList } from './transportOrders.types';

const baseUrl = `${config.backend.TRANSPORT_ORDERS_SERVICE}/transport-orders`;

export const transportOrdersApi = createApi({
    reducerPath: 'transportOrders',
    tagTypes: ['TransportOrders'],
    baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders }),
    endpoints: builder => ({
        getTransportOrders: builder.query<TransportOrderList, string | undefined>({
            query: () => ({
                url: '',
            }),
            providesTags: result => [
                ...(result?.items ?? []).map(({ id }) => ({ type: 'TransportOrders' as const, id })),
                { type: 'TransportOrders', id: 'LIST' },
            ],
            transformResponse: transportOrderListMapper.toDomain,
        }),

        getTransportOrder: builder.query<TransportOrder, string>({
            query: id => `/${id}`,
            providesTags: (result, error, id) => [{ type: 'TransportOrders', id }],
            transformResponse: transportOrderMapper.toDomain,
        }),
    }),
});

export const { useGetTransportOrdersQuery, useGetTransportOrderQuery } = transportOrdersApi;
