import { useEffect } from 'react';
import useClipboard from '@rio-cloud/rio-uikit/lib/es/useClipboard';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import Button from '@rio-cloud/rio-uikit/lib/es/Button';
import SimpleTooltip from '@rio-cloud/rio-uikit/lib/es/SimpleTooltip';

import { Shipment } from '../services/tours/tours.types';
import { getTrackingAttributes, TRACKING_CATEGORIES } from '../configuration/setup/googleAnalytics';
import { useDeliveryStatusLinks } from './DeliveryStatusLinkContext';

type DeliveryHeadlineProps = {
    deliveryName: string;
    link?: string;
};

type DeliveryDisplayProps = {
    shipment: Shipment;
    index: number;
};

const DeliveryHeadline = ({ deliveryName, link }: DeliveryHeadlineProps) => {
    if (!link) {
        return <span>{deliveryName}</span>;
    }

    return (
        <>
            <a
                href={link}
                target='_blank'
                rel='noreferrer'
                {...getTrackingAttributes({
                    trigger: 'click',
                    category: TRACKING_CATEGORIES.DELIVERY_STATUS,
                    action: 'Open tracking link button clicked',
                    label: 'Open tracking link button clicked',
                })}
            >
                {deliveryName}
            </a>
        </>
    );
};

const DeliveryDisplay = ({ shipment, index }: DeliveryDisplayProps) => {
    const clipboard = useClipboard();

    useEffect(() => {
        if (clipboard.copied) {
            Notification.success('Tracking-Link kopiert');
        }
    }, [clipboard.copied]);

    const links = useDeliveryStatusLinks();
    const link = links?.[shipment.id];

    const { externalReferenceType, externalReferencesValue, goodsDelivered, notes } = shipment.metadata ?? {};

    const externalReference =
        !!externalReferenceType && !!externalReferencesValue
            ? `${externalReferenceType} ${externalReferencesValue}`
            : '';

    const deliveryName = `Lieferung #${index + 1}`;

    return (
        <div className={'display-flex gap-5 justify-content-between padding-left-15 padding-10 border border-top-only'}>
            <div>
                <DeliveryHeadline deliveryName={deliveryName} link={link} />
                {externalReference && (
                    <div className='display-flex gap-5 text-italic text-color-darker'>
                        <span className='rioglyph rioglyph-tag text-color-dark padding-top-3' />
                        <div>{externalReference}</div>
                    </div>
                )}
                {goodsDelivered && (
                    <div className='display-flex gap-5 text-italic text-color-darker'>
                        <span className='rioglyph rioglyph-palette text-color-dark padding-top-3' />
                        <div>{goodsDelivered}</div>
                    </div>
                )}
                {notes && (
                    <div className='display-flex gap-5 text-italic text-color-darker'>
                        <span className='rioglyph rioglyph-comment text-color-dark padding-top-3' />
                        <div>{notes}</div>
                    </div>
                )}
            </div>
            {link && (
                <SimpleTooltip placement='bottom' content='Tracking-Link kopieren'>
                    <Button
                        iconOnly
                        bsSize='sm'
                        onClick={() => clipboard.copy(link)}
                        {...getTrackingAttributes({
                            trigger: 'click',
                            category: TRACKING_CATEGORIES.DELIVERY_STATUS,
                            action: 'Copy tracking link button clicked',
                            label: 'Copy tracking link button clicked',
                        })}
                    >
                        <span className='rioglyph rioglyph-clipboard' />
                    </Button>
                </SimpleTooltip>
            )}
        </div>
    );
};

export default DeliveryDisplay;
