import {
    Address,
    ApiAddress,
    ApiContact,
    ApiCoordinates,
    ApiLatestPosition,
    ApiLocation,
    ApiMeansOfTransport,
    ApiShipment,
    ApiTimeWindow,
    ApiTour,
    ApiTourExecutionState,
    ApiTourExecutionStateList,
    ApiTourList,
    ApiTourStop,
    ApiTourStopExecutionState,
    Contact,
    Coordinates,
    LatestPosition,
    Location,
    MeansOfTransport,
    Shipment,
    TimeWindow,
    Tour,
    TourExecutionState,
    TourExecutionStateList,
    TourList,
    TourStop,
    TourStopExecutionState,
} from './tours.types';
import {
    ApiAddressCodec,
    ApiContactCodec,
    ApiCoordinatesCodec,
    ApiLatestPositionCodec,
    ApiLocationCodec,
    ApiMeansOfTransportCodec,
    ApiShipmentCodec,
    ApiTimeWindowCodec,
    ApiTourCodec,
    ApiTourExecutionStateCodec,
    ApiTourExecutionStateListCodec,
    ApiTourListCodec,
    ApiTourStopCodec,
    ApiTourStopExecutionStateCodec,
} from './tours.codecs';
import { makeMapper } from '../makeMapper';

export const meansOfTransportMapper = makeMapper<MeansOfTransport, ApiMeansOfTransport>({
    codec: ApiMeansOfTransportCodec,
    toDomain: ({ asset_id: assetId }) => ({ assetId }),
    fromDomain: ({ assetId: asset_id }) => ({ asset_id }),
});

export const timeWindowMapper = makeMapper<TimeWindow, ApiTimeWindow>({ codec: ApiTimeWindowCodec });

export const coordinatesMapper = makeMapper<Coordinates, ApiCoordinates>({ codec: ApiCoordinatesCodec });

export const addressMapper = makeMapper<Address, ApiAddress>({
    codec: ApiAddressCodec,
    toDomain: ({ street, postal_code: postalCode, city, country }) => ({ street, postalCode, city, country }),
    fromDomain: ({ street, postalCode: postal_code, city, country }) => ({ street, postal_code, city, country }),
});

export const locationMapper = makeMapper<Location, ApiLocation>({
    codec: ApiLocationCodec,
    toDomain: ({ coordinates, address }) => ({
        coordinates: coordinatesMapper.toDomain(coordinates),
        address: address && addressMapper.toDomain(address),
    }),
    fromDomain: ({ coordinates, address }) => ({
        coordinates: coordinatesMapper.fromDomain(coordinates),
        address: address && addressMapper.fromDomain(address),
    }),
});

export const contactMapper = makeMapper<Contact, ApiContact>({
    codec: ApiContactCodec,
    toDomain: ({ name, phone, email, has_agreed_to_receive_emails: hasAgreedToReceiveEmails, language }) => ({
        name,
        phone,
        email,
        hasAgreedToReceiveEmails,
        language,
    }),
    fromDomain: ({ name, phone, email, hasAgreedToReceiveEmails: has_agreed_to_receive_emails, language }) => ({
        name,
        phone,
        email,
        has_agreed_to_receive_emails,
        language,
    }),
});

export const shipmentMapper = makeMapper<Shipment, ApiShipment>({
    codec: ApiShipmentCodec,
    toDomain: ({ id, transport_order_id: transportOrderId, type, metadata, contact }) => ({
        id,
        transportOrderId,
        type,
        metadata,
        contact: contact ? contactMapper.toDomain(contact) : undefined,
    }),
    fromDomain: ({ id, transportOrderId: transport_order_id, type, metadata, contact }) => ({
        id,
        transport_order_id,
        type,
        metadata,
        contact: contact ? contactMapper.fromDomain(contact) : undefined,
    }),
});

export const tourStopMapper = makeMapper<TourStop, ApiTourStop>({
    codec: ApiTourStopCodec,
    toDomain: ({ id, planned_arrival, location, metadata, shipments }) => ({
        id,
        plannedArrival: timeWindowMapper.toDomain(planned_arrival),
        location: locationMapper.toDomain(location),
        metadata,
        shipments: shipments.map(shipmentMapper.toDomain),
    }),
    fromDomain: ({ id, plannedArrival, location, metadata, shipments }) => ({
        id,
        planned_arrival: timeWindowMapper.fromDomain(plannedArrival),
        location: locationMapper.fromDomain(location),
        metadata,
        shipments: shipments.map(shipmentMapper.fromDomain),
    }),
});

export const tourMapper = makeMapper<Tour, ApiTour>({
    codec: ApiTourCodec,
    toDomain: ({ id, account_id: accountId, means_of_transport, stops, metadata, status }) => ({
        id,
        accountId,
        meansOfTransport: means_of_transport && meansOfTransportMapper.toDomain(means_of_transport),
        stops: stops.map(tourStopMapper.toDomain),
        metadata,
        status,
    }),
    fromDomain: ({ id, accountId: account_id, meansOfTransport, stops, metadata }) => ({
        id,
        account_id,
        means_of_transport: meansOfTransport && meansOfTransportMapper.fromDomain(meansOfTransport),
        stops: stops.map(tourStopMapper.fromDomain),
        metadata,
    }),
});

export const tourListMapper = makeMapper<TourList, ApiTourList>({
    codec: ApiTourListCodec,
    toDomain: ({ items }) => ({
        items: items
            .map(tourMapper.toDomain)
            .sort((a, b) => b.stops[0].plannedArrival.from.localeCompare(a.stops[0].plannedArrival.from)),
    }),
    fromDomain: ({ items }) => ({ items: items.map(tourMapper.fromDomain) }),
});

export const latestPositionMapper = makeMapper<LatestPosition, ApiLatestPosition>({
    codec: ApiLatestPositionCodec,
    toDomain: ({ latitude, longitude, occurred_at: occurredAt }) => ({
        latitude,
        longitude,
        occurredAt,
    }),
    fromDomain: ({ latitude, longitude, occurredAt: occurred_at }) => ({
        latitude,
        longitude,
        occurred_at,
    }),
});

export const tourStopExecutionStateMapper = makeMapper<TourStopExecutionState, ApiTourStopExecutionState>({
    codec: ApiTourStopExecutionStateCodec,
    toDomain: ({ tour_stop_id: tourStopId, status, eta }) => ({
        tourStopId,
        status,
        eta,
    }),
    fromDomain: ({ tourStopId: tour_stop_id, status, eta }) => ({
        tour_stop_id,
        status,
        eta,
    }),
});

export const tourExecutionStateMapper = makeMapper<TourExecutionState, ApiTourExecutionState>({
    codec: ApiTourExecutionStateCodec,
    toDomain: ({ tour_id: tourId, stop_execution_states: stopExecutionStates, latest_position: latestPosition }) => ({
        tourId,
        stopExecutionStates: stopExecutionStates.map(tourStopExecutionStateMapper.toDomain),
        latestPosition: latestPosition && latestPositionMapper.toDomain(latestPosition),
    }),
    fromDomain: ({ tourId: tour_id, stopExecutionStates: stop_execution_states, latestPosition: latest_position }) => ({
        tour_id,
        stop_execution_states: stop_execution_states.map(tourStopExecutionStateMapper.fromDomain),
        latest_position: latest_position && latestPositionMapper.fromDomain(latest_position),
    }),
});

export const tourExecutionStateListMapper = makeMapper<TourExecutionStateList, ApiTourExecutionStateList>({
    codec: ApiTourExecutionStateListCodec,
    toDomain: ({ items }) => ({
        items: items.map(tourExecutionStateMapper.toDomain),
    }),
    fromDomain: ({ items }) => ({ items: items.map(tourExecutionStateMapper.fromDomain) }),
});
