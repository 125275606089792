import { useScripts } from './useScripts';

const DEFAULT_BASE_URL = 'https://js.api.here.com/v3/3.1.20.0/';

const HERE_EXTERNALS = [
    'mapsjs-core.js',
    'mapsjs-core-legacy.js',
    'mapsjs-service.js',
    'mapsjs-service-legacy.js',
    'mapsjs-mapevents.js',
    'mapsjs-ui.js',
    'mapsjs-clustering.js',
];

const getExternals = (scriptList: string[], baseUrl: string): string[] =>
    scriptList.map(script => `${baseUrl}${script}`);

const sanitizeBaseUrl = (url: string) => (url.endsWith('/') ? url : `${url}/`);

export const useHereMap = (baseUrl?: string) => {
    const hereScripts = getExternals(HERE_EXTERNALS, sanitizeBaseUrl(baseUrl || DEFAULT_BASE_URL));
    return useScripts(hereScripts, false);
};
