import { Suspense } from 'react';
import { useHereMap } from '../../hooks/useHereMap';

type MapLoaderProps = {
    children: JSX.Element;
    fallback?: JSX.Element | null;
};

const MapLoader = ({ children, fallback = null }: MapLoaderProps) => {
    const { isLoading, error, hasLoaded } = useHereMap();

    console.debug({ isLoading, error, hasLoaded });

    if (!hasLoaded) {
        return fallback;
    }

    return <Suspense fallback={fallback}>{children}</Suspense>;
};

export default MapLoader;
