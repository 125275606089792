import {
    Address,
    ApiAddress,
    ApiConfirmationByContractor,
    ApiArticle,
    ApiArticleQuantity,
    ApiCancellation,
    ApiCargoEmpties,
    ApiCargoMaterial,
    ApiCoordinates,
    ApiDimension,
    ApiGoods,
    ApiHandlingUnit,
    ApiLoading,
    ApiLocation,
    ApiPackable,
    ApiParty,
    ApiTimeWindow,
    ApiOrder,
    ApiOrderList,
    ApiUnloading,
    ApiWeight,
    Article,
    Cancellation,
    ConfirmationByContractor,
    Coordinates,
    Dimension,
    Goods,
    HandlingUnit,
    Loading,
    Location,
    Packable,
    Party,
    TimeWindow,
    Order,
    OrderList,
    Unloading,
    Shipment,
    ApiShipment,
} from './orderExchange.types';
import {
    ApiAddressCodec,
    ApiArticleCodec,
    ApiArticleQuantityCodec,
    ApiCancellationCodec,
    ApiCargoEmptiesCodec,
    ApiCargoMaterialCodec,
    ApiConfirmationByContractorCodec,
    ApiConfirmedOrderCodec,
    ApiCoordinatesCodec,
    ApiDimensionCodec,
    ApiGoodsCodec,
    ApiHandlingUnitCodec,
    ApiLoadingCodec,
    ApiLocationCodec,
    ApiPackableCodec,
    ApiPartyCodec,
    ApiShipmentCodec,
    ApiTimeWindowCodec,
    ApiOrderCodec,
    ApiOrderListCodec,
    ApiUnloadingCodec,
    ApiWeightCodec,
} from './orderExchange.codecs';
import { makeMapper } from '../makeMapper';

export const timeWindowMapper = makeMapper<TimeWindow, ApiTimeWindow>({
    codec: ApiTimeWindowCodec,
    toDomain: ({ start_at: startAt, finish_at: finishAt }) => ({ startAt, finishAt }),
});

export const coordinatesMapper = makeMapper<Coordinates, ApiCoordinates>({ codec: ApiCoordinatesCodec });

export const addressMapper = makeMapper<Address, ApiAddress>({
    codec: ApiAddressCodec,
    toDomain: ({ street, postal_code: postalCode, city, country }) => ({ street, postalCode, city, country }),
});

export const locationMapper = makeMapper<Location, ApiLocation>({
    codec: ApiLocationCodec,
    toDomain: ({ coordinates, address }) => ({
        coordinates: coordinates && coordinatesMapper.toDomain(coordinates),
        address: address && addressMapper.toDomain(address),
    }),
});

export const partyMapper = makeMapper<Party, ApiParty>({
    codec: ApiPartyCodec,
    toDomain: ({ name, account_id: accountId, address }) => ({
        name,
        accountId,
        address: address && addressMapper.toDomain(address),
    }),
});

export const loadingMapper = makeMapper<Loading, ApiLoading>({
    codec: ApiLoadingCodec,
    toDomain: ({ time_window: timeWindow, shipper, location, note, place_of_loading: placeOfLoading }) => ({
        timeWindow: timeWindowMapper.toDomain(timeWindow),
        shipper: partyMapper.toDomain(shipper),
        location: locationMapper.toDomain(location),
        note: note,
        placeOfLoading: placeOfLoading,
    }),
});

export const unloadingMapper = makeMapper<Unloading, ApiUnloading>({
    codec: ApiUnloadingCodec,
    toDomain: ({ recipient, location, time_window: timeWindow, note, place_of_discharge: placeOfDischarge }) => ({
        recipient: partyMapper.toDomain(recipient),
        location: locationMapper.toDomain(location),
        timeWindow: timeWindow && timeWindowMapper.toDomain(timeWindow),
        note: note,
        placeOfDischarge: placeOfDischarge,
    }),
});

export const weightMapper = makeMapper<number | undefined, ApiWeight>({
    codec: ApiWeightCodec,
    toDomain: ({ gross_weight_kg: weight }) => weight,
});

export const dimensionMapper = makeMapper<Dimension, ApiDimension>({
    codec: ApiDimensionCodec,
    toDomain: ({ length_m: lengthInMeters, width_m: widthInMeters, height_m: heightInMeters }) => ({
        lengthInMeters,
        widthInMeters,
        heightInMeters,
    }),
});

export const articleQuantityMapper = makeMapper<number, ApiArticleQuantity>({
    codec: ApiArticleQuantityCodec,
    toDomain: ({ type, value }) => value,
});

export const packableMapper: any = makeMapper<Packable, ApiPackable>({
    codec: ApiPackableCodec,
    toDomain: packable =>
        packable.type === 'handling-unit'
            ? handlingUnitMapper.toDomain(packable as ApiHandlingUnit)
            : articleMapper.toDomain(packable as ApiArticle),
});

export const articleMapper = makeMapper<Article, ApiArticle>({
    codec: ApiArticleCodec,
    toDomain: ({ id, quantity }) => ({ type: 'article', id, quantity: articleQuantityMapper.toDomain(quantity) }),
});

export const handlingUnitMapper: any = makeMapper<HandlingUnit, ApiHandlingUnit>({
    codec: ApiHandlingUnitCodec,
    toDomain: ({
        packaging_code: packagingCode,
        quantity,
        contents,
        dimension,
        weight,
        stacking_factor: stackingFactor,
    }) => ({
        type: 'handling-unit',
        packagingCode,
        quantity,
        contents: contents.map(packableMapper.toDomain),
        dimension: dimension && dimensionMapper.toDomain(dimension),
        weight: weight && weightMapper.toDomain(weight),
        stackingFactor,
    }),
});

export const goodsMapper: any = makeMapper<Goods, ApiGoods>({
    codec: ApiGoodsCodec,
    toDomain: goods =>
        goods.type === 'cargo-material'
            ? cargoMaterialMapper.toDomain(goods as ApiCargoMaterial)
            : cargoEmptiesMapper.toDomain(goods as ApiCargoEmpties),
});

export const cargoEmptiesMapper = makeMapper<Goods, ApiCargoEmpties>({
    codec: ApiCargoEmptiesCodec,
    toDomain: () => ({
        type: 'cargo-empties',
        handlingUnits: [],
    }),
});

export const cargoMaterialMapper = makeMapper<Goods, ApiCargoMaterial>({
    codec: ApiCargoMaterialCodec,
    toDomain: ({ handling_units: handlingUnits }) => ({
        type: 'cargo-material',
        handlingUnits: handlingUnits.map(handlingUnitMapper.toDomain),
    }),
});

export const cancellationMapper = makeMapper<Cancellation, ApiCancellation>({
    codec: ApiCancellationCodec,
    toDomain: ({ status }) => ({
        status,
    }),
});

export const shipmentMapper = makeMapper<Shipment, ApiShipment>({
    codec: ApiShipmentCodec,
    toDomain: ({
        id,
        loading,
        unloading,
        goods,
        cancellation,
        ordering_party_shipment_id: orderingPartyShipmentId,
    }) => ({
        id,
        loading: loadingMapper.toDomain(loading),
        unloading: unloadingMapper.toDomain(unloading),
        goods: goodsMapper.toDomain(goods),
        cancellation: cancellation && cancellationMapper.toDomain(cancellation),
        orderingPartyShipmentId: orderingPartyShipmentId,
    }),
});

export const confirmationByContractorMapper = makeMapper<ConfirmationByContractor, ApiConfirmationByContractor>({
    codec: ApiConfirmationByContractorCodec,
    toDomain: ({ status }) => ({
        status,
    }),
});

export const orderMapper = makeMapper<Order, ApiOrder>({
    codec: ApiOrderCodec,
    toDomain: ({
        id,
        ordering_party: orderingParty,
        ordering_party_transport_order_id: orderingPartyTransportOrderId,
        contractor,
        confirmation_by_contractor: confirmationByContractor,
        _embedded,
        etag,
    }) => ({
        id: id,
        orderingParty: partyMapper.toDomain(orderingParty),
        orderingPartyTransportOrderId: orderingPartyTransportOrderId,
        contractor: partyMapper.toDomain(contractor),
        confirmationByContractor:
            confirmationByContractor && confirmationByContractorMapper.toDomain(confirmationByContractor),
        shipments: (_embedded?.shipments || Array<ApiShipment>()).map(shipmentMapper.toDomain),
        etag,
    }),
});

export const orderListMapper = makeMapper<OrderList, ApiOrderList>({
    codec: ApiOrderListCodec,
    toDomain: ({ items }) => ({ items: items.map(orderMapper.toDomain) }),
});
