import {
    Address,
    ApiAddress,
    ApiCoordinates,
    ApiLoading,
    ApiLocation,
    ApiParty,
    ApiTimeWindow,
    ApiTransportOrder,
    ApiTransportOrderList,
    ApiUnloading,
    Coordinates,
    Loading,
    Location,
    Party,
    TimeWindow,
    TransportOrder,
    TransportOrderList,
    Unloading,
    TransportOrderExecutionState,
    ApiTransportOrderExecutionState,
} from './transportOrders.types';
import {
    ApiAddressCodec,
    ApiCoordinatesCodec,
    ApiLoadingCodec,
    ApiLocationCodec,
    ApiPartyCodec,
    ApiTimeWindowCodec,
    ApiTransportOrderCodec,
    ApiTransportOrderListCodec,
    ApiUnloadingCodec,
    ApiTransportOrderExecutionStateCodec,
} from './transportOrders.codecs';
import { makeMapper } from '../makeMapper';

export const timeWindowMapper = makeMapper<TimeWindow, ApiTimeWindow>({
    codec: ApiTimeWindowCodec,
    toDomain: ({ start_at: startAt, finish_at: finishAt }) => ({ startAt, finishAt }),
});

export const coordinatesMapper = makeMapper<Coordinates, ApiCoordinates>({ codec: ApiCoordinatesCodec });

export const addressMapper = makeMapper<Address, ApiAddress>({
    codec: ApiAddressCodec,
    toDomain: ({ street, postal_code: postalCode, city, country }) => ({ street, postalCode, city, country }),
});

export const locationMapper = makeMapper<Location, ApiLocation>({
    codec: ApiLocationCodec,
    toDomain: ({ coordinates, address }) => ({
        coordinates: coordinates && coordinatesMapper.toDomain(coordinates),
        address: address && addressMapper.toDomain(address),
    }),
});

export const partyMapper = makeMapper<Party, ApiParty>({
    codec: ApiPartyCodec,
    toDomain: ({ name, account_id: accountId, address }) => ({
        name,
        accountId,
        address: address && addressMapper.toDomain(address),
    }),
});

export const loadingMapper = makeMapper<Loading, ApiLoading>({
    codec: ApiLoadingCodec,
    toDomain: ({ time_window: timeWindow, shipper, location, note, place_of_loading: placeOfLoading }) => ({
        timeWindow: timeWindowMapper.toDomain(timeWindow),
        shipper: partyMapper.toDomain(shipper),
        location: locationMapper.toDomain(location),
        note: note,
        placeOfLoading: placeOfLoading,
    }),
});

export const unloadingMapper = makeMapper<Unloading, ApiUnloading>({
    codec: ApiUnloadingCodec,
    toDomain: ({ recipient, location, time_window: timeWindow, note, place_of_discharge: placeOfDischarge }) => ({
        recipient: partyMapper.toDomain(recipient),
        location: locationMapper.toDomain(location),
        timeWindow: timeWindow && timeWindowMapper.toDomain(timeWindow),
        note: note,
        placeOfDischarge: placeOfDischarge,
    }),
});

export const transportOrderExecutionStateMapper = makeMapper<
    TransportOrderExecutionState,
    ApiTransportOrderExecutionState
>({
    codec: ApiTransportOrderExecutionStateCodec,
});

export const transportOrderMapper = makeMapper<TransportOrder, ApiTransportOrder>({
    codec: ApiTransportOrderCodec,
    toDomain: ({ id, root_id: shipmentId, external_id: externalId, loading, unloading, _embedded }) => ({
        id,
        rootId: shipmentId,
        externalId,
        loading: loadingMapper.toDomain(loading),
        unloading: unloadingMapper.toDomain(unloading),
        executionState:
            _embedded?.transport_order_execution_state &&
            transportOrderExecutionStateMapper.toDomain(_embedded?.transport_order_execution_state),
    }),
});

export const transportOrderListMapper = makeMapper<TransportOrderList, ApiTransportOrderList>({
    codec: ApiTransportOrderListCodec,
    toDomain: ({ items }) => ({ items: items.map(transportOrderMapper.toDomain) }),
});
