import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../config';
import { prepareHeaders } from '../../configuration/tokenHandling/prepareHeaders';
import { orderListMapper, orderMapper } from './orderExchange.mapper';
import { Order, OrderList } from './orderExchange.types';

const baseUrl = `${config.backend.ORDER_EXCHANGE_SERVICE}/transport-orders`;

export const orderExchangeApi = createApi({
    reducerPath: 'orders',
    tagTypes: ['OrderExchange'],
    baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders }),
    endpoints: builder => ({
        getOrders: builder.query<OrderList, string | undefined>({
            query: () => ({
                url: '?embed=(shipments)',
            }),
            providesTags: result => [
                ...(result?.items ?? []).map(({ id }) => ({ type: 'OrderExchange' as const, id })),
                { type: 'OrderExchange', id: 'LIST' },
            ],
            transformResponse: orderListMapper.toDomain,
        }),

        getOrder: builder.query<Order, string>({
            query: id => `/${id}`,
            providesTags: (result, error, id) => [{ type: 'OrderExchange', id }],
            transformResponse: orderMapper.toDomain,
        }),
    }),
});

export const { useGetOrdersQuery, useGetOrderQuery } = orderExchangeApi;
