import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../config';
import { prepareHeaders } from '../../configuration/tokenHandling/prepareHeaders';
import { Tour, TourExecutionState, TourList } from './tours.types';
import { tourExecutionStateMapper, tourListMapper, tourMapper } from './tours.mapper';

const baseUrl = `${config.backend.TOUR_SERVICE}`;

export const toursApi = createApi({
    reducerPath: 'tours',
    tagTypes: ['Tours', 'TourExecutionStates'],
    baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders }),
    endpoints: builder => ({
        getTours: builder.query<TourList, string | undefined>({
            query: assetId => (assetId ? `/tours?asset_id=${assetId}` : '/tours'),
            providesTags: result => [
                ...(result?.items ?? []).map(({ id }) => ({ type: 'Tours' as const, id })),
                { type: 'Tours', id: 'LIST' },
            ],
            transformResponse: tourListMapper.toDomain,
        }),

        getTour: builder.query<Tour, string>({
            query: id => `/tours/${id}`,
            providesTags: (result, error, id) => [{ type: 'Tours', id }],
            transformResponse: tourMapper.toDomain,
        }),

        putTour: builder.mutation<void, Tour>({
            query: tour => ({
                url: `/tours/${tour.id}`,
                method: 'PUT',
                body: tourMapper.fromDomain(tour),
            }),
            invalidatesTags: [{ type: 'Tours', id: 'LIST' }],
        }),

        getTourExecutionState: builder.query<TourExecutionState, string>({
            query: tourId => `/tour-execution-states/${tourId}`,
            providesTags: (result, error, id) => [{ type: 'TourExecutionStates', id }],
            transformResponse: tourExecutionStateMapper.toDomain,
        }),
    }),
});

export const { useGetToursQuery, useGetTourQuery, usePutTourMutation, useGetTourExecutionStateQuery } = toursApi;
