import { ActionReducerMapBuilder, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../configuration/setup/store';
import { Asset, AssetList } from '../services/assetAdministration/assetAdministration.types';
import { assetAdministrationApi } from '../services/assetAdministration/assetAdministration.api';
import { MapCredentials, mapCredentialsApi, MapCredentialsList } from '../services/mapCredentialsApi';
import { AddressSuggestion, addressSuggestionsApi } from '../services/addressSuggestionsApi';
import { toursApi } from '../services/tours/tours.api';
import { Tour, TourExecutionState, TourList } from '../services/tours/tours.types';
import { orderExchangeApi } from '../services/orderExchange/orderExchange.api';
import { Order, OrderList } from '../services/orderExchange/orderExchange.types';

export type AppState = {
    sessionExpiredAcknowledged: boolean;
    tours?: Tour[];
    assets?: Asset[];
    mapCredentials?: MapCredentials;
    addressSuggestions?: AddressSuggestion[];
    selectedTourId?: string;
    selectedAssetId?: string;
    transportOrders?: Order[];
    tourExecutionState?: TourExecutionState;
};

const initialState: AppState = {
    sessionExpiredAcknowledged: false,
    tours: undefined,
    assets: undefined,
    mapCredentials: undefined,
    addressSuggestions: undefined,
    selectedTourId: undefined,
    selectedAssetId: undefined,
    tourExecutionState: undefined,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        hideSessionExpiredDialog: state => {
            state.sessionExpiredAcknowledged = true;
        },
        tourSelected: (state, action: PayloadAction<string | undefined>) => {
            state.selectedTourId = action.payload;
        },
        selectAsset: (state, action: PayloadAction<string | undefined>) => {
            state.selectedAssetId = action.payload;
        },
        assetsStored: (state, action: PayloadAction<Asset[]>) => {
            state.assets = action.payload;
        },
        mapCredentialsStored: (state, action: PayloadAction<MapCredentials>) => {
            state.mapCredentials = action.payload;
        },
        addressSuggestionsStored: (state, action: PayloadAction<AddressSuggestion[]>) => {
            state.addressSuggestions = action.payload;
        },
    },
    extraReducers: (builder: ActionReducerMapBuilder<AppState>) => {
        builder.addMatcher(toursApi.endpoints.getTours.matchFulfilled, (state, action: PayloadAction<TourList>) => {
            state.tours = action.payload.items;
        });
        builder.addMatcher(
            toursApi.endpoints.getTourExecutionState.matchFulfilled,
            (state, action: PayloadAction<TourExecutionState>) => {
                state.tourExecutionState = action.payload;
            }
        );
        builder.addMatcher(
            assetAdministrationApi.endpoints.fetchAssets.matchFulfilled,
            (state, action: PayloadAction<AssetList>) => {
                state.assets = action.payload.items;
            }
        );
        builder.addMatcher(
            mapCredentialsApi.endpoints.fetchMapCredentials.matchFulfilled,
            (state, action: PayloadAction<MapCredentialsList>) => {
                state.mapCredentials = action.payload.items[0];
            }
        );
        builder.addMatcher(
            addressSuggestionsApi.endpoints.fetchAddressSuggestions.matchFulfilled,
            (state, action: PayloadAction<AddressSuggestion[]>) => {
                state.addressSuggestions = action.payload;
            }
        );
        builder.addMatcher(
            orderExchangeApi.endpoints.getOrders.matchFulfilled,
            (state, action: PayloadAction<OrderList>) => {
                state.transportOrders = action.payload.items;
            }
        );
    },
});

export const { hideSessionExpiredDialog, tourSelected, selectAsset, assetsStored, mapCredentialsStored } =
    appSlice.actions;

export const getSessionExpiredAcknowledged = (state: RootState) => state.app.sessionExpiredAcknowledged;

export const getSelectedTourId = (state: RootState) => state.app.selectedTourId;
export const getSelectedAssetId = (state: RootState) => state.app.selectedAssetId;

export const getTourList = (state: RootState) => state.app.tours;

export const getTransportOrderList = (state: RootState) => state.app.transportOrders;

export const getSelectedTour = createSelector(getTourList, getSelectedTourId, (tours, selectedTourId) =>
    tours?.find(tour => tour.id === selectedTourId)
);

export const getHereApiKey = (state: RootState) => state.app.mapCredentials?.api_key;

export default appSlice.reducer;
