import { config } from '../../config';
import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { prepareHeaders } from '../../configuration/tokenHandling/prepareHeaders';
import { DeliveryStatusLinks } from './delivery-status-links.types';
import { deliveryStatusLinksMapper } from './delivery-status-links.mapper';

const baseUrl = `${config.backend.DELIVERY_STATUS_SERVICE}/delivery-status-links`;

export const deliveryStatusLinksApi = createApi({
    reducerPath: 'deliveryStatusLinks',
    baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders }),
    endpoints: builder => ({
        getDeliveryStatusLinks: builder.query<DeliveryStatusLinks, string>({
            query: tourId => `?tour_id=${tourId}`,
            transformResponse: deliveryStatusLinksMapper.toDomain,
        }),
    }),
});

export const { useGetDeliveryStatusLinksQuery } = deliveryStatusLinksApi;
