import * as t from 'io-ts';
import { isRight } from 'fp-ts/Either';

const identity = (x: any) => x;

type MakeMapperOptions<DomainType, ApiType> = {
    codec: t.TypeC<any> | t.IntersectionC<any> | t.RecordC<any, any>;
    toDomain?: (value: ApiType) => DomainType;
    fromDomain?: (value: DomainType) => ApiType;
};

export const makeMapper = <DomainType, ApiType>(options: MakeMapperOptions<DomainType, ApiType>) => {
    const { codec, fromDomain = identity, toDomain = identity } = options;

    return {
        fromDomain: fromDomain as (x: DomainType) => ApiType,

        toDomain: (value: ApiType) => {
            const validation = codec.decode(value);
            if (isRight(validation)) {
                return toDomain(validation.right) as DomainType;
            } else {
                const errors = JSON.stringify(validation.left, null, '  ');
                throw new Error(`Failed to map value. Validation errors:\n${errors}`);
            }
        },
    };
};
