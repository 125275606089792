import StopDisplay from './StopDisplay';
import { TourStop, TourStopExecutionState } from '../services/tours/tours.types';
import DeliveryStatusLinkContextProvider from './DeliveryStatusLinkContext';

type StopListProps = {
    tourId: string;
    stops: TourStop[];
    stopExecutionStates?: TourStopExecutionState[];
};

const StopList = ({ tourId, stops, stopExecutionStates }: StopListProps) => (
    <DeliveryStatusLinkContextProvider tourId={tourId}>
        <div className='display-flex flex-column flex-1-1 timeline'>
            {stops.map((stop, index) => {
                const isFirstStop = index === 0;
                const isLastStop = index === stops.length - 1;
                const stopExecutionState = stopExecutionStates?.find(state => state.tourStopId === stop.id);
                return (
                    <StopDisplay
                        key={stop.id}
                        stop={stop}
                        stopExecutionState={stopExecutionState}
                        isFirstStop={isFirstStop}
                        isLastStop={isLastStop}
                    />
                );
            })}
        </div>
    </DeliveryStatusLinkContextProvider>
);

export default StopList;
