import { lazy } from 'react';
import { useNavigate } from 'react-router';
import Sidebar from '@rio-cloud/rio-uikit/Sidebar';

import { DEFAULT_ROUTE } from '../../routes/routes';
import { useAppSelector } from '../../configuration/setup/hooks';
import { getSelectedTour } from '../../layout/appSlice';
import StopList from '../../components/StopList';
import MapLoader from './MapLoader';
import { config } from '../../config';
import {
    HistoricPositionQueryArg,
    useGetHistoricPositionsForAssetQuery,
} from '../../services/asset-history/asset-history.api';
import { useGetTourExecutionStateQuery } from '../../services/tours/tours.api';

const MiniMap = lazy(() => import('./MiniMap'));

const getFleetMonitorLink = (assetId: string | undefined): string => {
    const baseUrl = config.backend.LIVE_MONITOR_WEB;
    return !!baseUrl && !!assetId ? `${baseUrl}/#map/${assetId}?tabId=4` : '';
};

const TourSidebar = () => {
    const navigate = useNavigate();
    const selectedTour = useAppSelector(getSelectedTour);
    const now = new Date().toISOString();
    const plannedFrom = selectedTour?.stops[0].plannedArrival.from!;
    const from = selectedTour?.status !== 'planned' && now < plannedFrom ? now : plannedFrom;

    const plannedTo = selectedTour?.stops[selectedTour.stops.length - 1].plannedArrival.to!;
    const to = selectedTour?.status !== 'planned' && now < plannedTo ? now : plannedTo;

    const tourId = selectedTour?.id;
    const assetId = selectedTour?.meansOfTransport?.assetId;

    const { data: executionState, isLoading: isLoadingExecutionState } = useGetTourExecutionStateQuery(tourId || '', {
        skip: !tourId,
        refetchOnFocus: true,
        pollingInterval: 10000,
    });

    const tourExecutionState = executionState?.tourId === tourId ? executionState : undefined;

    const { data: positions } = useGetHistoricPositionsForAssetQuery(
        {
            assetId,
            from,
            to,
        } as HistoricPositionQueryArg,
        {
            skip: !(assetId && from && to),
            refetchOnFocus: true,
            pollingInterval: 10000,
        }
    );

    const handleClose = () => navigate(`${DEFAULT_ROUTE}`);

    const fleetMonitorLink = getFleetMonitorLink(assetId);

    return (
        <Sidebar
            title='Details zur Tour'
            titleClassName='padding-left-10'
            closed={!selectedTour}
            onClose={handleClose}
            resizable
            fly
            onBackdropClick={handleClose}
            enableFullscreenToggle
            position='right'
            minWidth={300}
            width={550}
        >
            <div className='padding-left-20 padding-right-20'>
                {selectedTour && !isLoadingExecutionState && (
                    <>
                        <div className='width-100pct height-250 padding-bottom-20'>
                            <MapLoader>
                                <MiniMap tour={selectedTour} positions={positions || []} />
                            </MapLoader>
                        </div>
                        {fleetMonitorLink && (
                            <a
                                className='btn btn-link btn-component btn-primary'
                                href={fleetMonitorLink}
                                target='_blank'
                                rel='noreferrer'
                            >
                                <span className='rioglyph rioglyph-new-window' />
                                <span>Zum Flottenmonitor</span>
                            </a>
                        )}
                        <div className='panel padding-15 margin-top-15'>
                            <StopList
                                tourId={selectedTour.id}
                                stops={selectedTour.stops}
                                stopExecutionStates={tourExecutionState?.stopExecutionStates}
                            />
                        </div>
                    </>
                )}
            </div>
        </Sidebar>
    );
};

export default TourSidebar;
