import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import configReducer from './configSlice';
import loginReducer from '../login/loginSlice';
import langReducer from '../lang/langSlice';
import tokenReducer from '../tokenHandling/tokenSlice';
import appReducer from '../../layout/appSlice';
import { assetAdministrationApi } from '../../services/assetAdministration/assetAdministration.api';
import { mapCredentialsApi } from '../../services/mapCredentialsApi';
import { addressSuggestionsApi } from '../../services/addressSuggestionsApi';
import { toursApi } from '../../services/tours/tours.api';
import { orderExchangeApi } from '../../services/orderExchange/orderExchange.api';
import { deliveryStatusLinksApi } from '../../services/delivery-status-links/delivery-status-links.api';
import { assetHistoryApi } from '../../services/asset-history/asset-history.api';
import { transportOrdersApi } from '../../services/transportOrders/transportOrders.api';

export const store = configureStore({
    reducer: {
        config: configReducer,
        lang: langReducer,
        app: appReducer,
        login: loginReducer,
        tokenHandling: tokenReducer,

        // Add the generated reducer as a specific top-level slice
        [assetAdministrationApi.reducerPath]: assetAdministrationApi.reducer,
        [mapCredentialsApi.reducerPath]: mapCredentialsApi.reducer,
        [addressSuggestionsApi.reducerPath]: addressSuggestionsApi.reducer,
        [toursApi.reducerPath]: toursApi.reducer,
        [orderExchangeApi.reducerPath]: orderExchangeApi.reducer,
        [transportOrdersApi.reducerPath]: transportOrdersApi.reducer,
        [deliveryStatusLinksApi.reducerPath]: deliveryStatusLinksApi.reducer,
        [assetHistoryApi.reducerPath]: assetHistoryApi.reducer,
    },

    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat([
            assetAdministrationApi.middleware,
            mapCredentialsApi.middleware,
            addressSuggestionsApi.middleware,
            toursApi.middleware,
            orderExchangeApi.middleware,
            transportOrdersApi.middleware,
            deliveryStatusLinksApi.middleware,
            assetHistoryApi.middleware,
        ]),
});

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
